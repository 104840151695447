import ServiceApi from '../ServiceApi';

export default class ServiceReconciliationImport {
    static _microserviceName = 'free';

    static _requestRouts = {
        attachFile: '/match/file-upload',
        getSetting: {
            city: '/match/cities',
            supplier: '/match/properties?attribute=supplier_id',
            type: '/match/properties?attribute=type_id',
            kind: '/match/properties?attribute=kind_id',
            format: '/match/properties?attribute=format_id',
            materials: '/match/properties?attribute=material_id'
        },
        getMarkingValues: '/match/fields',
        runMatch: '/match/sheet',
        getTableDownload: {
            found: '/match/found',
            unfound: '/match/not-found',
            listError: '/match/list-error'
        },
        downloadMatch: '/match/to-pre-fact',
        getTemplates: '/template/list',
        setTemplate: {
            create: '/template/create',
            update: '/template/update',
        },
        setTemplateLastUpdate: '/template/set-last-updated',
        deleteTemplate: '/template/delete',
        getPreview: '/match/file-sheets',
        rowEditMatch: '/match/row-edit',
        rowRemoveMatch: '/match/remove-rows'

    };

    static _tableColumnsMarkingScheme = [
        // {
        //     label: "",
        //     prop: "infoError",
        //     isSlot: true,
        //     columnStyles: {width: '50px'}
        // },
        {
            label: "Выберите данные",
            prop: "markingValue",
            isSlot: true,
            columnStyles: {
                'width': '320px',
                'display': 'flex',
                'justify-content': 'center',
                'align-items': 'center',
            }
        },
        {
            label: "Заголовок из файла",
            prop: "titleFile",
            columnStyles: { width: '315px', overflow: 'hidden' }
        },
        {
            label: "Пример данных в столбце",
            prop: "dataColumn",
            columnStyles: { width: '315px', overflow: 'hidden' }
        }
    ];

    static _tableColumnsDownloadScheme = [
        {
            prop: 'supplier_entity',
            apiKey: 'supplier_entity',
            label: 'Юр. лицо аренды',
            isSlot: true,
        },
        {
            prop: 'city',
            apiKey: 'city',
            label: 'Город',
            isSlot: true,
        },
        {
            prop: 'address',
            apiKey: 'address',
            label: 'Адрес',
            isSlot: true,
        },
        {
            prop: 'supplier',
            apiKey: 'supplier',
            label: 'Подрядчик',
            isSlot: true,
        },
        {
            prop: 'gid',
            apiKey: 'gid',
            label: 'GID',
            isSlot: true,
        },
        {
            prop: 'kind',
            apiKey: 'kind',
            label: 'Вид',
            isSlot: true,
        },
        {
            prop: 'type',
            apiKey: 'type',
            label: 'Тип',
            isSlot: true,
        },
        {
            prop: 'format',
            apiKey: 'format',
            label: 'Формат',
            isSlot: true,
        },
        {
            prop: 'side',
            apiKey: 'side',
            label: 'Сторона',
            isSlot: true,
        },
        {
            prop: 'lat',
            apiKey: 'lat',
            label: 'Широта',
            isSlot: true,
        },
        {
            prop: 'lng',
            apiKey: 'lng',
            label: 'Долгота',
            isSlot: true,
        }
    ];

    static _settingsGetValueScheme = {
        city: (item) => ({id: String(item?.id ?? ''), value: String(item?.name ?? '')}),
        properties: (item) => ({id: String(item?.id ?? ''), value: String(item?.value ?? '')}),
        supplier: (item) => ({id: String(item?.id ?? ''), value: String(item?.value ?? '')}),
        type: (item) => ({id: String(item?.id ?? ''), value: String(item?.value ?? '')}),
        kind: (item) => ({id: String(item?.id ?? ''), value: String(item?.value ?? '')}),
        format: (item) => ({id: String(item?.id ?? ''), value: String(item?.value ?? '')}),
        materials: (item) => ({id: String(item?.id ?? ''), value: String(item?.value ?? '')})
    };

    static _runMatchActionScheme = {
        approved: 'pre_fact',
        reconciliation: 'revise'
    }

    static async attachFile(file = null) {
        return this._attachFile(file);
    }

    static async _attachFile(file = null) {
        const reqBody = this._attachFileBefore(file);
        const reqHeader = {headers: {'Content-Type': 'multipart/form-data'}};

        try {
            const resBody = await ServiceApi.post(this._microserviceName, this._requestRouts.attachFile, reqBody, reqHeader);
            const {data = {}} = resBody;
            const {data: fileData = {}} = data;
            const fileDataAfter = this._attachFileAfter(fileData, file, 0, 1);

            return fileDataAfter;
        } catch (error) {
            console.log(error);
            const errorMsg = this._attachFileError(error);

            throw new Error(errorMsg);
        }
    }

    static _attachFileBefore(file = null) {
        let formData = new FormData();
        formData.append('file', file);

        return formData;
    }

    static _attachFileAfter(fileData = {}, file = null, startColumnIndex = 0, startRowIndex = 0) {
        const {data: {meta: fileBody = []} = {}, file_id: fileId = ''} = fileData;
        const fileBodyAfter = fileBody.reduce((fileBodyAfter, fileBodyItem) => {
            const {name: tabListItemName = '', index: tabListItemId = '', columns = [], rows = []} = fileBodyItem;

            const listData = [columns, ...rows];
            const tableTitleFile = listData[startColumnIndex] ?? [];
            const tableDataColumn = listData[startRowIndex] ?? [];

            const tabListItem = {id: String(tabListItemId), name: String(tabListItemName)};
            fileBodyAfter.tabList.push(tabListItem);

            const tabListItemRows = tableTitleFile.reduce((tabListItemRows, _, index) => {
                const titleFile = String(tableTitleFile[index]?.value ?? '');
                const dataColumn = String(tableDataColumn[index]?.value ?? '');

                if(titleFile !== '' || dataColumn !== '') {
                    const tabListItemRow = {
                        id: String(index),
                        markingValue: [],
                        infoError: false,
                        titleFile: String(tableTitleFile[index]?.value ?? ''),
                        dataColumn: String(tableDataColumn[index]?.value ?? '')
                    };
                    tabListItemRows.push(tabListItemRow);
                }

                return tabListItemRows;
            }, []);
            fileBodyAfter.tableRows = {
                ...fileBodyAfter.tableRows,
                [tabListItemId]: [...tabListItemRows]
            };

            return fileBodyAfter;
        }, {tabList: [], tableRows: {}});

        const fileDataAfter = {
            fileId: String(fileId),
            file,
            tabIdActive: String(fileBodyAfter.tabList[0]?.id ?? ''),
            tableColumns: [...this._tableColumnsMarkingScheme],
            ...fileBodyAfter,
            extraData: {...fileData}
        };

        return fileDataAfter;
    }

    static _attachFileError(error) {
        const {response = {}} = error;
        const {data = {}} = response;
        const {data: dataError = {}} = data;
        const {file = []} = dataError;

        return file[0] ?? '';
    }

    static async getMarkingValues(activeTabId) {
        return this._getMarkingValues(activeTabId);
    }

    static async _getMarkingValues(activeTabId) {
        try {
            const resBody = await ServiceApi.get(
                this._microserviceName,
                this._requestRouts.getMarkingValues + "?action=" + this._runMatchActionScheme[activeTabId]
            );
            const {data = {}} = resBody;
            const {data: markingValuesData = {}} = data;
            const markingValuesDataAfter = this._getMarkingValuesAfter(markingValuesData);

            return markingValuesDataAfter;
        } catch (error) {
            console.log(error);

            throw new Error(error);
        }
    }

    static _getMarkingValuesAfter(markingValuesData = {}) {
        const {side: simpleItems = {}, price: multiItems = []} = markingValuesData;
        const markingValuesMap = {
            simple: simpleItems,
            multi: multiItems
        };
        const markingValues = Object.keys(markingValuesMap).reduce((markingValues, markingValueType) => {
            const markingValuesByType = markingValuesMap[markingValueType];
            const markingValuesAfter = markingValueType === 'multi' ? this._getMarkingValuesMultiAfter(markingValuesByType) : this._getMarkingValuesSimpleAfter(markingValuesByType);
            markingValues.push(...markingValuesAfter);

            return markingValues;
        }, []);

        return markingValues;
    }

    static _getMarkingValuesSimpleAfter(markingValuesByType = {}) {
        const markingValuesAfter = Object.keys(markingValuesByType).map(markingValueKey => {
            const markingValue = {
                id: String(markingValueKey),
                value: String(markingValuesByType[markingValueKey] ?? ''),
                type: 'simple'
            };
            return markingValue;
        });

        return markingValuesAfter;
    }

    static _getMarkingValuesMultiAfter(markingValuesByType = []) {
        const markingValuesAfter = markingValuesByType.map(markingValueByType => {
            const markingValue = {
                id: String(markingValueByType?.key ?? ''),
                value: String(markingValueByType?.label ?? ''),
                type: 'multi',
                dates: [],
                groupKey: String(markingValueByType?.group?? ''),
                groupLabel: String(markingValueByType?.group_label ?? '')
            };

            return markingValue;
        });

        return markingValuesAfter;
    }

    static async getSettings(settingsIds = []) {
        return this._getSettings(settingsIds);
    }

    static async _getSettings(settingsIds = []) {
        const requestPackage = settingsIds.map(settingId => this.getSetting(settingId, 1, ''));
        try {
            const settingsData = await Promise.all(requestPackage);

            return settingsData;
        } catch (error) {
            console.log(error);

            throw new Error(error);
        }
    }

    static async getSetting(attribute = '', page = 1, search = '') {
        return this._getSetting(attribute, page, search);
    }

    static async _getSetting(attribute = '', page = 1, search = '') {
        const queryParams = this._getSettingBefore(page, search);
        const route = this._requestRouts.getSetting[attribute] ?? '';

        try {
            const resBody = await ServiceApi.get(this._microserviceName, route, queryParams);
            const {data = {}} = resBody;
            const {data: settingData = {}} = data;
            const settingDataAfter = this._getSettingAfter(attribute, page, settingData);

            return settingDataAfter;
        } catch (error) {
            console.log(error);

            throw new Error(error);
        }
    }

    static _getSettingBefore(page = 1, search = '') {
        const queryParams = {
            'page': parseInt(page),
            'per-page': 20,
            'queryWord': String(search)
        };

        return queryParams;
    }

    static _getSettingAfter(attribute = '', page = 1, settingData = {}) {
        const {items: settingItems = [], totalCount = 0} = settingData;
        const settingsGetValues = this._settingsGetValueScheme[attribute] ?? (() => {});
        const settingItemsAfter = settingItems.map(settingItem => settingsGetValues(settingItem));
        const settingDataAfter = {
            id: String(attribute),
            items: settingItemsAfter,
            totalCount: parseInt(totalCount),
            page: parseInt(page)
        };

        return settingDataAfter;
    }

    static async runMatch(fileId = '', tabIdActiveMarking = '', tabIdActiveAttach = '', rowsMarkingSelected = [], settingsListIdsActive = [], startRowIndex = 0) {
        return this._runMatch(fileId, tabIdActiveMarking, tabIdActiveAttach, rowsMarkingSelected, settingsListIdsActive, startRowIndex);
    }

    static async _runMatch(fileId = '', tabIdActiveMarking = '', tabIdActiveAttach = '', rowsMarkingSelected = [], settingsListIdsActive = [], startRowIndex = 0) {
        const reqBody = this._runMatchBefore(fileId, tabIdActiveMarking, tabIdActiveAttach, rowsMarkingSelected, settingsListIdsActive, startRowIndex);

        try {
            await ServiceApi.post(this._microserviceName, this._requestRouts.runMatch, reqBody);
        } catch (error) {
            console.log(error);
            const errorMsg = this._runMatchError(error);
            throw new Error(errorMsg);
        }
    }

    static _runMatchBefore(fileId = '', tabIdActiveMarking = '', tabIdActiveAttach = '', rowsMarkingSelected = [], settingsListIdsActive = [], startRowIndex = 0) {
        const columnMap = this._getColumnMap(rowsMarkingSelected);
        const settingsMap = this._getSettingsMap(settingsListIdsActive);

        const reqBody = {
            file_id: parseInt(fileId),
            sheet_index: parseInt(tabIdActiveMarking),
            start_row_index: parseInt(startRowIndex),
            column_map: columnMap,
            action: String(this._runMatchActionScheme[tabIdActiveAttach] ?? ''),
            city_id: String(settingsMap?.cities ?? ''),
            supplier_id: String(settingsMap?.suppliers ?? '')
        };

        return reqBody;
    }

    static _runMatchError(error) {
        const {response = {}} = error;
        const {data = {}} = response;
        const {data: dataError = {}} = data;
        const errors = Object.keys(dataError).reduce((errors, errorKey) => {
            const errorsArr = dataError[errorKey] ?? [];
            errors.push(...errorsArr);
            return errors;
        }, []);

        return errors.join(', ');
    }

    static _getColumnMap(rowsMarkingSelected = []) {
        const columnMap = rowsMarkingSelected.reduce((columnMap, rowMarkingSelected) => {
            let columnMapItems = [];
            const {rowId = '', markingValueId = '', dates = [], type = ''} = rowMarkingSelected;

            if (type === 'simple') {
                columnMapItems.push({
                    name: String(markingValueId),
                    index: String(rowId)
                });
            }
            else if (type === 'multi') {
                const markingValueIdArr = String(markingValueId).split('|');
                const markingValueIdStart = markingValueIdArr[0] ?? '';
                const markingValueIdEnd = markingValueIdArr[1] ?? '';
                const columnMapNames = dates.map(date => {
                    const markingValueIdBefore = `${markingValueIdStart}|${date}`;
                    return markingValueIdEnd === '' ? markingValueIdBefore : `${markingValueIdBefore}|${markingValueIdEnd}`;
                });
                columnMapItems.push(...columnMapNames.map(columnMapName => ({
                    name: String(columnMapName),
                    index: String(rowId)
                })));
            }

            columnMap.push(...columnMapItems);
            return columnMap;
        }, []);

        return columnMap;
    }

    static _getSettingsMap(settingsListIdsActive = []) {
        const settingsMap = settingsListIdsActive.reduce((settingsMap, setting) => {
            settingsMap[setting?.id] = String(setting?.listIdActive);
            return settingsMap;
        }, {});

        return settingsMap;
    }

    static async getTableDownload(tableKeys = [], fileId = '', tabIdActiveMarking = '', rowsMarkingSelected = [], markingValues = []) {
        return this._getTableDownload(tableKeys, fileId, tabIdActiveMarking, rowsMarkingSelected, markingValues);
    }

    static async _getTableDownload(tableKeys = [], fileId = '', tabIdActiveMarking = '', rowsMarkingSelected = [], markingValues = []) {
        const requestPackage = tableKeys.map(tableKey => this.getTableRowsDownload(tableKey, fileId, tabIdActiveMarking, 1, rowsMarkingSelected, markingValues));
        try {
            const tableData = await Promise.all(requestPackage);
            const tableDataAfter = this._getTableDownloadAfter(tableData);

            return tableDataAfter;
        } catch (error) {
            console.log(error);

            throw new Error(error);
        }
    }

    static _getTableDownloadAfter(tableData = []) {
        const tableDataAfter = tableData.reduce((tableDataAfter, tableDataItem) => {
            const {tableKey = '', tableRows = [], tableRowsTotalCount = 0} = tableDataItem;

            tableDataAfter.tableRows[tableKey] = tableRows;
            tableDataAfter.tableRowsTotalCount[tableKey] = tableRowsTotalCount;

            return tableDataAfter;
        }, {tableRows: {}, tableRowsTotalCount: {}});

        return {tableColumns: tableData[0]?.tableColumns ?? [], ...tableDataAfter};
    }

    static async getTableRowsDownload(tableKey = '', fileId = '', tabIdActiveMarking = '', page = 1, rowsMarkingSelected = [], markingValues = []) {
        return this._getTableRowsDownload(tableKey, fileId, tabIdActiveMarking, page, rowsMarkingSelected, markingValues);
    }

    static async _getTableRowsDownload(tableKey = '', fileId = '', tabIdActiveMarking = '', page = 1, rowsMarkingSelected = [], markingValues = []) {
        const queryParams = this._getTableRowsDownloadBefore(tabIdActiveMarking, page);

        try {
            const resBody = await ServiceApi.get(this._microserviceName, `${this._requestRouts.getTableDownload[tableKey] ?? ''}/${fileId}`, queryParams);
            const {data = {}} = resBody;
            const {data: tableRowsData = {}} = data;
            const tableRowsDataAfter = this._getTableRowsDownloadAfter(tableKey, tableRowsData, rowsMarkingSelected, markingValues);

            return tableRowsDataAfter;
        } catch (error) {
            console.log(error);

            throw new Error(error);
        }
    }

    static _getTableRowsDownloadBefore(tabIdActiveMarking = '', page = 1) {
        const queryParams = {
            'sheet_index': parseInt(tabIdActiveMarking),
            'page': parseInt(page),
            'per-page': 2000
        };

        return queryParams;
    }

    static _getTableRowsDownloadAfter(tableKey = '', tableRowsData = {}, rowsMarkingSelected = [], markingValues = []) {
        const columnMap = this._getColumnMap(rowsMarkingSelected);

        let tableColumns = columnMap.map(column => {
            const columnName = String(column?.name ?? '');
            const columnNameArr = columnName.split('|');
            let columnProp = columnNameArr.length === 3 ? `${columnNameArr[0]}|${columnNameArr[2]}` : columnNameArr[0];

            const markingValue = markingValues.find(markingValue => String(markingValue?.id ?? '') === columnProp) ?? {};
            const columnLabel = String(markingValue?.value ?? '');

            const columnStyles = columnNameArr[0] === 'rent_cost' || columnNameArr[0] === 'supplier' ? { width: '250px' } : {};

            const tableColumn = {
                apiKey: columnName,
                isSlot: true,
                label: columnLabel,
                prop: columnName,
                columnStyles
            };

            return tableColumn;
        });
        tableColumns.push({
            prop: 'more_btn',
            label: '',
            isAction: true,
            isSlot: true,
            isShow: true,
            isSlotHeader:true,
            columnStyles: {
                width: '40px',
                minWidth: '40px',
                position: 'sticky',
                right: '0',
                zIndex: '2',
                padding: '0',
            },
            columnsSearchStyle: {
                width: '40px',
                minWidth: '40px',
                position: 'sticky',
                right: '0',
                padding: '0',
            }
        });

        const {items: rows = [], totalCount = 0} = tableRowsData;
        const tableRows = this._collectTableRowsDownload(tableColumns, rows);

        return {tableColumns, tableRows, tableKey, tableRowsTotalCount: parseInt(totalCount)};
    }

    static _collectTableRowsDownload(tableColumns = [], rows = []) {
        const rowKeys = tableColumns.map(tableColumnDownloadScheme => String(tableColumnDownloadScheme?.apiKey ?? ''));
        const tableRows = rows.map(row => {
            const {row: rowData = {}, row_index: rowIndex = '', errors = {}, differences = []} = row;
            const tableRow = rowKeys.reduce((tableRow, rowKey) => {
                const difference = differences?.find(difference => String(difference?.attribute ?? '') === rowKey) ?? null;

                tableRow[rowKey] = {
                    value: String(rowData[rowKey] ?? ''),
                    error: Array.from(errors[rowKey] ?? []).join(', '),
                    difference
                };

                return tableRow;
            }, {id: String(rowIndex)});

            return tableRow;
        });

        return tableRows;
    }

    static async downloadMatch(fileId = '', tabIdActiveMarking = '') {
        return this._downloadMatch(fileId, tabIdActiveMarking);
    }

    static async _downloadMatch(fileId = '', tabIdActiveMarking = '') {
        const reqBody = this._downloadMatchBefore(fileId, tabIdActiveMarking);

        try {
            await ServiceApi.post(this._microserviceName, this._requestRouts.downloadMatch, reqBody);

            return true;
        } catch (error) {
            const errorMsg = this._runMatchError(error);

            throw new Error(errorMsg);
        }


    }

    static _downloadMatchBefore(fileId = '', tabIdActiveMarking = '') {
        const reqBody = {
            file_id: parseInt(fileId),
            sheet_index: parseInt(tabIdActiveMarking),
            is_overwrite: 1
        };

        return reqBody;
    }

    static async getTemplates(templateType = '', markingValues = []) {
        return this._getTemplates(templateType, markingValues);
    }

    static async _getTemplates(templateType = '', markingValues = []) {
        try {
            const route = this._getTemplatesBefore(templateType);

            const res = await ServiceApi.get(this._microserviceName, route);
            const {data = {}} = res;
            const {data: templatesData = []} = data;
            const templates = this._getTemplatesAfter(templatesData, markingValues);
            return templates;
        } catch (error) {
            console.log(error);

            throw new Error(error);
        }
    }

    static _getTemplatesBefore(templateType = '') {
        return `${this._requestRouts.getTemplates}-${String(templateType)}`;
    }

    static _getTemplatesAfter(templates = [], markingValues = []) {
        return templates.map(template => {
            const {
                id = '',
                data = {}
            } = template;
            const {
                column_map: columnMap = [],
                city_id: cityId = '',
                supplier_id: supplierId = ''
            } = data;

            const rowsMarkingValue = columnMap.reduce((rowsMarkingValue, columnMapItem) => {
                const {index: rowId = '', name = ''} = columnMapItem;
                const nameArr = name.split('|');

                const markingValueType = nameArr.length > 1 ? 'multi' : 'simple';
                const markingValueId = nameArr.length === 3 ? `${nameArr[0]}|${nameArr[2]}` : `${nameArr[0] ?? ''}`;
                const markingValueDate = String(nameArr[1] ?? '');
                const markingValueExtra = markingValues.find(markingValue => String(markingValue?.id ?? '') === markingValueId) ?? {};
                const {value: markingValueName = ''} = markingValueExtra;

                let rowMarkingValue = rowsMarkingValue[rowId] ?? [];
                const index = rowMarkingValue.findIndex(rowMarkingValueItem => String(rowMarkingValueItem?.id ?? '') === markingValueId);
                if (index !== -1) {
                    const dates = rowMarkingValue[index]?.dates ?? [];
                    rowMarkingValue.splice(index, 1, {...rowMarkingValue[index], dates: [...dates, markingValueDate]});
                } else {
                    let rowMarkingValueAfter = {
                        id: markingValueId,
                        type: markingValueType,
                        value: String(markingValueName),
                    };
                    if (markingValueType === 'multi')
                        rowMarkingValueAfter.dates = [markingValueDate];
                    rowMarkingValue.push(rowMarkingValueAfter);
                }
                rowsMarkingValue[rowId] = rowMarkingValue;

                return rowsMarkingValue;
            }, {});

            const settingsValue = {
                cities: String(cityId),
                suppliers: String(supplierId)
            };

            const templateAfter = {
                id: String(id),
                extraData: {...template},
                rowsMarkingValue,
                settingsValue
            };

            return templateAfter;
        });
    }

    static async setTemplate({templateName = '', templateAdditingParams = {}, rowsMarkingSelected = [], settingsListIdsActive = [], startRowIndex = 0, markingValues = [], actionType = ''}) {
        return this._setTemplate({templateName, templateAdditingParams, rowsMarkingSelected, settingsListIdsActive, startRowIndex, markingValues, actionType});
    }

    static async _setTemplate({templateName = '', templateAdditingParams = {}, rowsMarkingSelected = [], settingsListIdsActive = [], startRowIndex = 0, markingValues = [], actionType = ''}) {
        const requestTypes = {create: 'post', update: 'put'};
        const reqBody = this._setTemplateBefore({templateName, templateAdditingParams, rowsMarkingSelected, settingsListIdsActive, startRowIndex});

        try {
            const res = await ServiceApi[requestTypes[actionType] ?? ''](this._microserviceName, this._requestRouts.setTemplate[actionType] ?? '', reqBody);
            const {data = {}} = res;
            const {data: templateData = {}} = data;
            const template = this._getTemplatesAfter([templateData], markingValues)[0] ?? {};
            return template;
        } catch (error) {
            console.log(error);

            throw new Error(error);
        }

    }

    static _setTemplateBefore({templateName = '', templateAdditingParams = {}, rowsMarkingSelected = [], settingsListIdsActive = [], startRowIndex = 0}) {
        const columnMap = this._getColumnMap(rowsMarkingSelected);
        const settingsMap = this._getSettingsMap(settingsListIdsActive);

        const reqBody = {
            ...templateAdditingParams,
            name: String(templateName),
            data: {
                start_row_index: parseInt(startRowIndex),
                column_map: columnMap,
                city_id: String(settingsMap?.cities ?? ''),
                supplier_id: String(settingsMap?.suppliers ?? '')
            }
        };

        return reqBody;
    }

    static async setTemplateLastUpdate(templateId = '') {
        return this._setTemplateLastUpdate(templateId);
    }

    static async _setTemplateLastUpdate(templateId = '') {
        const requestBody = { template_id: String(templateId) };

        try {
            await ServiceApi.put(this._microserviceName, `${this._requestRouts.setTemplateLastUpdate}/${templateId}`, requestBody);

            return true;
        } catch (error) {
            console.log(error);
        }
    }

    static async getPreview(fileId = '', sheetId = '') {
        return this._getPreview(fileId, sheetId);
    }

    static async _getPreview(fileId = '', sheetId = '') {
        const queryParams = { file_id: String(fileId) };

        try {
            const responseBody = await ServiceApi.get(this._microserviceName, `${this._requestRouts.getPreview}/${fileId}`, queryParams);
            const previewData = this._getPreviewAfter(responseBody, sheetId);

            return previewData;
        } catch (error) {
            console.log(error);
        }
    }

    static _getPreviewAfter(responseBody = {}, sheetId = '') {
        const sheets = responseBody?.data?.data?.sheets ?? [];
        const sheet = sheets.find(sheet => parseInt(sheet?.index ?? -1) === parseInt(sheetId)) ?? {};

        let columns = [{
            prop: 'id',
            label: '№',
            columnStyles: {
                'width': '50px'
            }
        }];

        columns.push(...sheet?.columns.map((column, index) => ({
            prop: String(index),
            label: String(column?.value ?? ''),
            // columnStyles: {
            //     'height': 'inherit',
            //     'text-overflow': 'ellipsis',
            //     'overflow': 'hidden',
            //     'display': '-webkit-box',
            //     '-webkit-line-clamp': '1',
            //     '-webkit-box-orient': 'vertical',
            //     'white-space': 'normal',
            //     'word-break': 'break-all'
            // }
        })));
        const rows = sheet?.rows.map((row, rowIndex) => {
            const rowAfter = row.reduce((rowAfter, rowColumn, index) => {
                rowAfter[index] = String(rowColumn?.value ?? '');

                return rowAfter;
            }, {});

            return {id: String(rowIndex + 1), ...rowAfter};
        });
        const previewData = { columns, rows };

        return previewData;
    }

    static async deleteTemplate(templateId = '') {
        return this._deleteTemplate(templateId);
    }

    static async _deleteTemplate(templateId = '') {
        try {
            await ServiceApi.delete(this._microserviceName, `${this._requestRouts.deleteTemplate}/${templateId}`);

            return true;
        } catch (error) {
            console.log(error);
        }
    }

    static async rowEditMatch(fileId = '', sheetId = '', rowId = '', columnProp = '', cellValue = '', tableColumns = []) {
        return this._rowEditMatch(fileId, sheetId, rowId, columnProp, cellValue, tableColumns);
    }

    static async _rowEditMatch(fileId = '', sheetId = '', rowId = '', columnProp = '', cellValue = '', tableColumns = []) {
        const requestBody = this._rowEditMatchBefore(fileId, sheetId, rowId, columnProp, cellValue);

        try {
            const response = await ServiceApi.put(this._microserviceName, this._requestRouts.rowEditMatch, requestBody);
            const row = response?.data?.data ?? {};
            const tableRows = this._collectTableRowsDownload(tableColumns, [row]);

            return tableRows[0] ?? {};
        } catch (error) {
            console.log(error);
        }
    }

    static _rowEditMatchBefore(fileId = '', sheetId = '', rowId = '', columnProp = '', cellValue = '') {
        const requestBody = {
            file_id: parseInt(fileId),
            sheet_index: parseInt(sheetId),
            row_index: parseInt(rowId),
            row_field: String(columnProp),
            row_field_value: String(cellValue),
            is_rematch: false
        };

        return requestBody;
    }

    static async rowRemoveMatch(fileId = '', sheetId = '', rowId = '') {
        return this._rowRemoveMatch(fileId, sheetId, rowId);
    }

    static async _rowRemoveMatch(fileId = '', sheetId = '', rowId = '') {
        const requestBody = this._rowRemoveMatchBefore(fileId, sheetId, rowId);

        try {
            await ServiceApi.post(this._microserviceName, this._requestRouts.rowRemoveMatch, requestBody);

            return true;
        } catch (error) {
            console.log(error);
        }
    }

    static _rowRemoveMatchBefore(fileId = '', sheetId = '', rowId = '') {
        const requestBody = {
            file_id: parseInt(fileId),
            sheet_index: parseInt(sheetId),
            row_indexes: [parseInt(rowId)]
        };

        return requestBody;
    }
}
